.highlight-posts {
  text-align: center;
  display: grid;
  height: 420px;
  grid-gap: 20px;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr 1fr 0;
  margin-bottom: 18px;
  .highlight-post {
    position: relative;
    box-sizing: border-box;
    transform: translateZ(0);
    .tile {
      background-color: #ebebeb;
      box-shadow: 0 2px 20px 0px rgb(0 0 0 / 5%);
      border-radius: 6px;
      transition: box-shadow 0.35s ease-out;
      position: relative;
      height: 100%;
      overflow: hidden;

      .postPhoto {
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 30px;
        overflow: hidden;
        display: block;
        position: relative;
        height: 100%;
        img {
          height: 100%;
          width: 100%;
          object-position: center;
          object-fit: cover;
          border: 0;
        }
        .share {
          position: absolute;
          right: 16px;
          top: 16px;
          width: 26px;
          box-sizing: border-box;
          opacity: 0;
          pointer-events: none;
          transition: opacity 0.35s 0.2s;
          z-index: 2;
          > span {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 26px;
            height: 26px;
            line-height: 26px;
            text-align: center;
            font-size: 12px;
            color: #4f575b;
            background-color: rgba(255, 255, 255, 0.9);
            margin-bottom: 5px;
            border-radius: 50%;
            box-sizing: border-box;
            box-shadow: 0 0 20px rgb(0 0 0 / 10%);
            cursor: pointer;
            transition:
              color 0.35s,
              background-color 0.35s;
          }
        }
        &::before {
          content: "";
          background: linear-gradient(
            top,
            transparent 0%,
            rgba(0, 0, 0, 0.3) 100%
          );
          background: -webkit-linear-gradient(
            top,
            transparent 0%,
            rgba(0, 0, 0, 0.3) 100%
          );
          position: absolute;
          display: block;
          width: 100%;
          bottom: 0;
          top: 0;
          opacity: 1;
          z-index: 1;
        }
      }
      .postMeta {
        position: absolute;
        width: 100%;
        bottom: -60px;
        opacity: 1;
        font-size: 10px;
        color: #999;
        transition-property: bottom, opacity, background;
        transition-duration: 0.4s;
        transition-delay: 0.08s;
        height: 60px;
        text-align: left;
        z-index: 1;
        background: rgba(0, 0, 0, 0.2);
        .postInfo {
          position: absolute;
          color: #fff;
          bottom: 60px;
          width: 100%;
          display: table-cell;
          vertical-align: baseline;
          transition:
            bottom 0.3s ease-out,
            opacity 0.3s 0.18s ease-out;
          pointer-events: none;
          background: linear-gradient(
            180deg,
            rgba(0, 0, 0, 0),
            rgba(0, 0, 0, 0.2)
          );
          h1 {
            font-size: 18px;
            font-weight: 600;
            line-height: 24px;
            padding: 0 15px;
            position: relative;
            transition-property: bottom, opacity;
            transition-duration: 0.4s;
            transition-delay: 0;
            margin-bottom: 5px;
            text-align: left;
            line-height: 18px;
            overflow: hidden;
            white-space: normal;
            letter-spacing: 0.5px;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
          .source {
            display: block;
            text-align: left;
            margin-right: 10px;
            font-size: 12px;
            color: rgba(255, 255, 255, 0.8);
            margin: 5px 0 15px 0;
            padding: 0px 15px;
          }
        }
        &:hover {
          bottom: 0;
        }
        .details {
          display: block;
          height: 60px;
          padding: 15px;
          box-sizing: border-box;
          .favorite {
            cursor: pointer;
            display: block;
            width: 26px;
            height: 26px;
            float: left;
            margin-top: 2px;
            background-color: rgba(255, 255, 255, 0.4);
            color: #fff;
            line-height: 26px;
            text-align: center;
            font-size: 12px;
            border-radius: 50%;
            transition:
              color 0.35s,
              border 0.35s;
          }
          .postMenu {
            display: inline-block;
            position: relative;
            z-index: 100;
          }
          .stats {
            color: #fff;
            line-height: 30px;
            font-size: 11px;
            > span {
              background-position: left center;
              background-repeat: no-repeat;
              background-size: 15px auto;
              margin-left: 12px;
              transition: color 0.35s;
            }
          }
        }
      }

      &:hover {
        .postMeta {
          transition-delay: 0.18s;
          bottom: 0;
        }
      }
    }
    &:hover {
      .postPhoto .share {
        opacity: 1 !important;
        pointer-events: all;
        position: absolute;
        right: 16px;
        top: 16px;
      }
    }
  }
}

@media screen and (min-width: 1645px) {
  .highlight-posts {
    grid-template-columns: repeat(5, 1fr);
  }
  .highlight-posts .highlight-post:first-child {
    grid-column: 1 / 3;
    grid-row: 1 / 3;
  }
  .highlight-posts .highlight-post:first-child .postMeta .postInfo h1 {
    font-size: 18px;
    line-height: 24px;
    padding-right: 20%;
    margin-bottom: 7px;
  }
  .highlight-posts .highlight-post:nth-child(n + 8) {
    display: none;
  }
  .highlight-posts > .highlight-post.dynamic {
    display: inline-block;
  }
}

@media screen and (min-width: 1347px) and (max-width: 1644px) {
  .highlight-posts {
    grid-template-columns: repeat(4, 1fr);
  }
  .highlight-posts > .highlight-post:first-child {
    grid-column: 1 / 3;
    grid-row: 1 / 3;
  }
  .highlight-posts > .highlight-post:nth-child(n + 6) {
    display: none;
    visibility: hidden;
  }
  .highlight-posts > .highlight-post.dynamic {
    display: inline-block;
  }
}

@media screen and (min-width: 1151px) and (max-width: 1346px) {
  .highlight-posts {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr 0 0;
  }
  .highlight-posts .highlight-post:nth-child(n + 5) {
    display: none;
    visibility: hidden;
  }
}

@media screen and (min-width: 901px) and (max-width: 1150px) {
  .highlight-posts {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr 0;
  }
  .highlight-posts .highlight-post:nth-child(n + 4) {
    display: none;
    visibility: hidden;
  }
}

@media screen and (max-width: 900px) {
  .highlight-posts {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr 0;
  }
  .highlight-posts .highlight-post:nth-child(n + 2) {
    display: none;
    visibility: hidden;
  }
}

@media screen and (max-width: 768px) {
  .highlight-posts {
    height: 260px;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: 1fr 0;
  }
  .highlight-posts .highlight-post:nth-child(n + 2) {
    display: none;
    visibility: hidden;
  }
}
