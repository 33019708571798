.snowflake {
  position: absolute;
  font-size: 1.5rem; /* Adjust size as needed */
  opacity: 0.8;
  pointer-events: none; /* Prevent interaction */
  animation: fall linear forwards;
}

@keyframes fall {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(100vh); /* Fall to the bottom of the viewport */
    opacity: 0; /* Fade out */
  }
}
